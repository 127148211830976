module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Arly","short_name":"Arly","start_url":"/","background_color":"#ffffff","theme_color":"#B38DEA","display":"minimal-ui","icon":"static/favicon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"ac633e1044f2e9af21960d80e85455ab"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["es"],"defaultLanguage":"es","fallbackLanguage":"es","siteUrl":"https://arly-online.com","redirect":false,"trailingSlash":"always","i18nextOptions":{"defaultNS":"common","interpolation":{"escapeValue":false},"keySeparator":".","nsSeparator":":"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager-cookie-consent/gatsby-browser.js'),
      options: {"plugins":[],"cookieConsentConfig":{"categories":{"necessary":{"enabled":true,"readOnly":true},"analytics":{}},"language":{"default":"es","translations":{"es":{"consentModal":{"title":"Uso de cookies","description":"El uso de cookies ayuda a mejorar el uso de este sitio web.","acceptAllBtn":"Aceptar todo","acceptNecessaryBtn":"Rechazar","showPreferencesBtn":"Manejar preferencias individuales"},"preferencesModal":{"title":"Manejar preferencias de cookies","acceptAllBtn":"Aceptar todo","acceptNecessaryBtn":"Rechazar","savePreferencesBtn":"Aceptar los seleccionados","closeIconLabel":"Cerrar","sections":[{"title":"Tipo de Cookies","description":"Permitir cookies de análisis para ayudar a mejorar el sitio web."},{"title":"Cookies estrictamente necesarias","description":"Estas cookies son esenciales para el correcto funcionamiento del sitio web y no se pueden desactivar.","linkedCategory":"necessary"},{"title":"Rendimiento y análisis","description":"Estas cookies recopilan información sobre cómo utilizas el sitio web. Todos los datos están anonimizados y no se pueden usar para identificarte.","linkedCategory":"analytics"},{"title":"Más información","description":"Para cualquier consulta relacionada con nuestra política de cookies y tus elecciones, por favor consulta nuestra <a href=\"/privacidad\">Política de Privacidad</a> y <a href=\"/cookies\">Política de Cookies</a>."}]}}}}},"id":"GTM-NF3NFPWM","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"dataLayerName":"dataLayer","routeChangeEventName":"gatsbyRouteChange","enableWebVitalsTracking":true,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
